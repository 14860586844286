import React from 'react';
import { Container, Ratio } from 'react-bootstrap';
import alexCv from '../pdf/alexCv.pdf';

function CvView() {
  return (
    <Container>
      <Ratio aspectRatio="16x9">
        <object data={alexCv} type="application/pdf">
          <embed title="cv" src={alexCv} type="application/pdf" />
        </object>
      </Ratio>
    </Container>
  );
}

export default CvView;
