import React from 'react';
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <Container>
        <a href="https://github.com/AFed29" className="social-media-link">
          <FontAwesomeIcon icon={faGithub} size="lg" />
        </a>
        <a
          href="https://www.linkedin.com/in/alexander-fedenczuk-8b5412159/"
          className="social-media-link"
        >
          <FontAwesomeIcon icon={faLinkedin} size="lg" />
        </a>
      </Container>
    </footer>
  );
}

export default Footer;
