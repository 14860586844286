import React from 'react';
import { Container, Image, Row } from 'react-bootstrap';
import forehead from '../img/forehead.jpg';

function Home() {
  return (
    <Container>
      <Row className="justify-content-md-center">
        <Image fluid src={forehead} />
      </Row>
    </Container>
  );
}

export default Home;
