import React from 'react';
import { Col, Container, Row, Card, ListGroup } from 'react-bootstrap';
import umpire from '../img/umpire.jpg';
import './AboutMe.css';

function AboutMe() {
  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col md>
          <Card>
            <Card.Header as="h1">Software Engineering</Card.Header>
            <Card.Body>
              <Card.Text>
                I am currently a Senior Consultant Software Engineer at{' '}
                <a href="https://www.infinityworks.com/">Infinity Works</a>,
                using a variety of languages, and cloud infrastructure. Having
                studied Software Development on CodeClan's 16 week course, I
                then started working at{' '}
                <a href="https://tvsquared.com/">TVSquared</a> where I started
                as an Intern software developer, progressing to a Junior and
                finally a Software Developer.
              </Card.Text>
              <Card.Text>
                A few of the languages and technologies that I have used so far
                include:
              </Card.Text>
              <ListGroup>
                <ListGroup.Item>
                  JavaScript
                  <ListGroup>
                    <ListGroup.Item>AngularJS</ListGroup.Item>
                    <ListGroup.Item>React</ListGroup.Item>
                    <ListGroup.Item>Vue</ListGroup.Item>
                  </ListGroup>
                </ListGroup.Item>
                <ListGroup.Item>
                  TypeScript
                  <ListGroup>
                    <ListGroup.Item>React</ListGroup.Item>
                  </ListGroup>
                </ListGroup.Item>
                <ListGroup.Item>
                  Python
                  <ListGroup>
                    <ListGroup.Item>Django</ListGroup.Item>
                    <ListGroup.Item>PySpark</ListGroup.Item>
                  </ListGroup>
                </ListGroup.Item>
                <ListGroup.Item>
                  AWS
                  <ListGroup>
                    <ListGroup.Item>S3</ListGroup.Item>
                    <ListGroup.Item>CloudFront</ListGroup.Item>
                    <ListGroup.Item>Route53</ListGroup.Item>
                  </ListGroup>
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
        <Col md>
          <Card>
            <Card.Header as="h1">Hockey</Card.Header>
            <Card.Body>
              <Card.Text>
                I've been involved in hockey since the age of 14, starting off
                as a goalkeeper and eventually becoming an umpire. For the last
                10 years, I have been umpiring in the top level of Scottish
                domestic competitions, and have also been lucky enough to
                represent Scotland and GB as an International Umpire. I have
                also gained both indoor and outdoor International gradings from
                the FIH(International Hockey Federation)
              </Card.Text>
              <Card.Img src={umpire} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default AboutMe;
