import React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import NavBar from './NavBar';
import Home from './Home';
import AboutMe from './AboutMe';
import CvView from './CvView';
import Footer from './Footer';
import './App.css';

function App() {
  return (
    <div data-testid="app-element">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="aboutme" element={<AboutMe />} />
          <Route path="cv" element={<CvView />} />
        </Route>
      </Routes>
    </div>
  );
}

function Layout() {
  return (
    <div className="d-flex flex-column" style={{ minHeight: '100vh' }}>
      <NavBar />
      <Outlet />
      <Footer />
    </div>
  );
}

export default App;
