import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar as BootstapNav, Nav } from 'react-bootstrap';
import './NavBar.css';
import { Container } from 'react-bootstrap';

export default () => {
  return (
    <BootstapNav
      sticky="top"
      className="menu-bar"
      expand="lg"
      bg="dark"
      variant="dark"
    >
      <Container fluid>
        <BootstapNav.Brand>Alex Fedenczuk</BootstapNav.Brand>
        <BootstapNav.Toggle aria-controls="main-navbar" />
        <BootstapNav.Collapse id="main-navbar">
          <Nav defaultActiveKey={'/'} className="mr-auto">
            <Nav.Link as={Link} to="/" eventKey="/">
              Home
            </Nav.Link>
            <Nav.Link as={Link} to="/aboutme" eventKey="/aboutme">
              About Me
            </Nav.Link>

            <Nav.Link as={Link} to="/cv" eventKey="/cv">
              CV
            </Nav.Link>
          </Nav>
        </BootstapNav.Collapse>
      </Container>
    </BootstapNav>
  );
};
